import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeadphones,
  faMusic,
  faShoppingBag,
} from '@fortawesome/free-solid-svg-icons'
import Link from 'gatsby-link'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Sticky from 'react-stickynode'
import uniqid from 'uniqid'

import styled from 'styled-components'
import media from 'styled-media-query'

import Logo from '../components/logo'

const Legacy = styled.div`
  max-width: 800px;
  margin: auto;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Arial', sans-serif;
  }
  a,
  p,
  li {
    font-family: 'Arial', sans-serif;
    font-size: 120%;
  }
`

const Title = styled.h2`
  font-size: 125%;
  color: crimson;
`

const LogoBox = styled.div`
  svg {
    width: 100px;
  }
`

const About = styled.div`
  margin: 24px 0;
  display: flex;
  justify-content: space-between;
  img {
    height: 100%;
  }
`

const AboutText = styled.div`
  max-width: 400px;
  h1 {
    font-size: 200%;
  }
`

const MoreAboutText = styled.div`
  max-width: 600px;
`

const StaffList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 36px 0;
`

const Staff = styled.div`
  width: 20%;
  padding: 0 20px 24px;
  img {
    width: 100%;
    height: 100%;
  }
  h3 {
    margin-top: 10px;
    margin-bottom: 0;
  }
  h4 {
    color: crimson;
    margin-top: 0;
    margin-bottom: 0;
  }
  span {
    font-family: sans-serif;
    font-size: 80%;
  }
  p {
    font-size: 100%;
  }
`

const PressList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Press = styled.div`
  width: 12.5%;
  padding: 0 24px;
  img {
    height: 100%;
    width: 100%;
  }
`

const MoreList = styled.ul`
  display: inline-flex;
  li {
    margin-right: 8px;
  }
`

const LegacyPage = ({
  page = null,
  tech = null,
  jingle = null,
  techIO = null,
  jingleIO = null,
}) => (
  <StaticQuery
    query={graphql`
      query LegacyQuery {
        allAboutJson {
          edges {
            node {
              main {
                title
                text
                image {
                  childImageSharp {
                    fluid(maxHeight: 300) {
                      src
                    }
                  }
                }
              }
              tech {
                title
                text
                image {
                  childImageSharp {
                    fluid(maxHeight: 300) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
        allStaffJson {
          edges {
            node {
              staff {
                name
                title
                description
                thumbnail {
                  childImageSharp {
                    fluid(maxWidth: 200) {
                      src
                    }
                  }
                }
                alumni
              }
            }
          }
        }
        allCharityJson {
          edges {
            node {
              title
              sponsors
            }
          }
        }
        allEventJson {
          edges {
            node {
              title
              main {
                message
                image {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
              tech {
                message
                image {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
        allPressJson {
          edges {
            node {
              featured {
                name
                url
                image {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
              extra {
                name
                image {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const about = data.allAboutJson.edges[0].node
      const staff = data.allStaffJson.edges[0].node.staff
      const events = data.allEventJson.edges[0].node
      const charity = data.allCharityJson.edges[0].node
      const press = data.allPressJson.edges[0].node
      return (
        <Legacy>
          <LogoBox>
            <Logo />
          </LogoBox>
          <div id="about">
            <Title>About Us</Title>
            <hr />
            {about.main.map(ctx => (
              <About key={uniqid()}>
                <AboutText>
                  <h1>{ctx.title}</h1>
                  <p>{ctx.text}</p>
                </AboutText>
                <img src={ctx.image.childImageSharp.fluid.src} />
              </About>
            ))}
            <Title>Behind the Scene</Title>
            <hr />
            {about.tech.map(ctx => (
              <About key={uniqid()}>
                <MoreAboutText>
                  <h1>{ctx.title}</h1>
                  <p>{ctx.text}</p>
                </MoreAboutText>
              </About>
            ))}
          </div>
          <Title>Our Team</Title>
          <hr />
          <div id="staff">
            <StaffList>
              {staff.map(person => (
                <Staff key={uniqid()}>
                  <div>
                    <img src={person.thumbnail.childImageSharp.fluid.src} />
                  </div>
                  <h3>{person.name}</h3>
                  <h4>{person.title}</h4>
                  {person.alumni && <span>Alumni</span>}
                  <p>{person.description}</p>
                </Staff>
              ))}
            </StaffList>
          </div>
          <div id="events" />
          <Title>People Talk About Us!</Title>
          <hr />
          <div id="press">
            <PressList>
              {press.featured.map(ctx => (
                <Press key={uniqid()}>
                  <a href={ctx.url} target="_blank">
                    <img
                      src={ctx.image.childImageSharp.fluid.src}
                      alt={ctx.name}
                    />
                  </a>
                </Press>
              ))}
            </PressList>
            <h3 align={'center'}>
              <em>And More</em>
            </h3>
            {press.extra.map(ctx => (
              <MoreList key={uniqid()}>
                <li>{ctx.name}</li>
              </MoreList>
            ))}
          </div>
        </Legacy>
      )
    }}
  />
)

export default LegacyPage
